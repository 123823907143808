import React from "react"
import Layout from "../components/layout"

const TermsOfService = () => (
  <Layout>
    <div className="w-full flex justify-center content-center bg-white py-16">
      <div className="w-full tablet:w-3/4 desktop:3/4 2k:3/4 flex justify-center">
        <div className=" flex flex-col pb-8 pt-6 prose prose-green">
          <div className="text-4xl font-display font-bold desktop:mb-2">
            Terms of Service
          </div>

          <div className="column">
            <p className="font-body">
              These terms and conditions outline the rules and regulations for
              the use of The Infinite Voice Project, Org&#39;s Website. By
              accessing this website we assume you accept these terms and
              conditions in full. Do not continue to use The Infinite Voice
              Project, Org&#39;s website if you do not accept all of the terms
              and conditions stated on this page.
            </p>
            <p className="font-body">
              The following terminology applies to these Terms and Conditions,
              Privacy Statement and Disclaimer Notice and any or all Agreements:
              &#34;Client&#34;, &#34;You&#34; and &#34;Your&#34; refers to you,
              the person accessing this website and accepting the Company&#39;s
              terms and conditions. &#34;The Company&#34;, &#34;Ourselves&#34;,
              &#34;We&#34;, &#34;Our&#34; and &#34;Us&#34;, refers to our
              Company. &#34;Party&#34;, &#34;Parties&#34;, or &#34;Us&#34;,
              refers to both the Client and ourselves, or either the Client or
              ourselves. All terms refer to the offer, acceptance and
              consideration of payment necessary to undertake the process of our
              assistance to the Client in the most appropriate manner, whether
              by formal meetings of a fixed duration, or any other means, for
              the express purpose of meeting the Client&#39;s needs in respect
              of provision of the Company&#39;s stated services/products, in
              accordance with and subject to, prevailing law of .
            </p>
            <p className="font-body">
              Any use of the above terminology or other words in the singular,
              plural, capitalization and/or he/she or they, are taken as
              interchangeable and therefore as referring to same.Cookies We
              employ the use of cookies. By using The Infinite Voice Project,
              Org&#39;s website you consent to the use of cookies in accordance
              with The Infinite Voice Project, Org&#39;s privacy policy. Most of
              the modern day interactive web sites use cookies to enable us to
              retrieve user details for each visit. Cookies are used in some
              areas of our site to enable the functionality of this area and
              ease of use for those people visiting. Some of our affiliate /
              advertising partners may also use cookies.
            </p>

            <div className="font-display text-xl font-bold">License</div>
            <p className="font-body">
              Unless otherwise stated, The Infinite Voice Project, Org. and/or
              it&#39;s licensors own the intellectual property rights for all
              material on The Infinite Voice Project, Org. All intellectual
              property rights are reserved. You may view and/or print pages
              from&nbsp;
              <a href="https://theinfinitevoiceproject.org">
                https://theinfinitevoiceproject.org
              </a>
              &nbsp;for your own personal use subject to restrictions set in
              these terms and conditions. You must not:
            </p>

            <ul className="list-disc py-4 mx-12">
              <li>
                <p className="font-body">
                  Republish material from&nbsp;
                  <a href="https://theinfinitevoiceproject.org">
                    https://theinfinitevoiceproject.org
                  </a>
                </p>
              </li>
              <li>
                <p className="font-body">
                  Sell, rent or sub-license material from&nbsp;
                  <a href="https://theinfinitevoiceproject.org">
                    https://theinfinitevoiceproject.org
                  </a>
                </p>
              </li>
              <li>
                <p className="font-body">
                  Reproduce, duplicate or copy material from&nbsp;
                  <a href="https://theinfinitevoiceproject.org">
                    https://theinfinitevoiceproject.org
                  </a>
                </p>
              </li>
              <li>
                <p className="font-body">
                  Redistribute content from The Infinite Voice Project, Org
                  (unless content is specifically made for redistribution).
                </p>
              </li>
            </ul>

            <div className="text-xl font-display font-bold">
              Hyperlinking To Our Content
            </div>
            <p className="font-body">
              Organizations may link to our home page, to publications or to
              other Web site information so long as the link: (a) is not in any
              way misleading; (b) does not falsely imply sponsorship,
              endorsement or approval of the linking party and its products or
              services; and (c) fits within the context of the linking
              party&#39;s site.
            </p>

            <div className="font-display text-lg font-semibold">Iframes</div>
            <p className="font-body">
              Without prior approval and express written permission, you may not
              create frames around our Web pages or use other techniques that
              alter in any way the visual presentation or appearance of our Web
              site.
            </p>

            <div className="font-display text-lg font-semibold">
              Reservation of Rights
            </div>
            <p className="font-body">
              We reserve the right at any time and in its sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request. We also reserve the right to amend these
              terms and conditions and its linking policy at any time. By
              continuing to link to our Web site, you agree to be bound to and
              abide by these linking terms and conditions.
            </p>

            <div className="font-display text-lg font-semibold">
              Link Removal
            </div>
            <p className="font-body">
              If you find any link on our Web site or any linked web site
              objectionable for any reason, you may contact us about this. We
              will consider requests to remove links but will have no obligation
              to do so or to respond directly to you. Whilst we endeavor to
              ensure that the information on this website is correct, we do not
              warrant its completeness or accuracy; nor do we commit to ensuring
              that the website remains available or that the material on the
              website is kept up to date.
            </p>

            <div className="font-display text-lg font-semibold">
              Content Liability
            </div>
            <p className="font-body">
              We shall have no responsibility or liability for any content
              appearing on your Web site. You agree to indemnify and defend us
              against all claims arising out of or based upon your Website. No
              link(s) may appear on any page on your Web site or within any
              context containing content or materials that may be interpreted as
              libelous, obscene or criminal, or which infringes, otherwise
              violates, or advocates the infringement or other violation of, any
              third party rights.
            </p>

            <div className="font-display text-lg font-semibold">Disclaimer</div>
            <p className="font-body">
              To the maximum extent permitted by applicable law, we exclude all
              representations, warranties and conditions relating to our website
              and the use of this website (including, without limitation, any
              warranties implied by law in respect of satisfactory quality,
              fitness for purpose and/or the use of reasonable care and skill).
              Nothing in this disclaimer will:
            </p>

            <ul className="list-disc mx-12 py-4">
              <li>
                <p className="font-body">
                  limit or exclude our or your liability for death or personal
                  injury resulting from negligence;
                </p>
              </li>
              <li>
                <p className="font-body">
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </p>
              </li>
              <li>
                <p className="font-body">
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </p>
              </li>
              <li>
                <p className="font-body">
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </p>
              </li>
            </ul>
            <p className="font-body">
              The limitations and exclusions of liability set out in this
              Section and elsewhere in this disclaimer: (a) are subject to the
              preceding paragraph; and (b) govern all liabilities arising under
              the disclaimer or in relation to the subject matter of this
              disclaimer, including liabilities arising in contract, in tort
              (including negligence) and for breach of statutory duty. To the
              extent that the website and the information and services on the
              website are provided free of charge, we will not be liable for any
              loss or damage of any nature.
            </p>
            <div className="text-xl font-display font-bold">
              Credit & Contact Information
            </div>
            <p className="font-body">
              This Terms and conditions page was created with
              <a href="http://termsandconditionstemplate.com">
                {" "}
                termsandconditionstemplate.com
              </a>{" "}
              generator. If you have any queries regarding any of our terms,
              please contact us.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsOfService
